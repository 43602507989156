<template>
  <nav-bar></nav-bar>
  <transition name="slide" mode="out-in">
      <router-view />
  </transition>
  <footer-section></footer-section>
</template>
<script>
  import NavBar from './views/partils/NavView.vue';
  import FooterSection from './views/partils/FooterView.vue';
  export default{
   components: {
    NavBar,
    FooterSection
   },
  }
</script>
<style>
.slide-enter-from{
  opacity: 0;
  transform: translateX(100px);
}
.slide-enter-active {
  transition: all .9s ease-out;
}
.slide-leave-to{
  opacity: 0;
  transform: translateX(-100px);
}
.slide-leave-active {
  transition: all .7s ease-in;
}
</style>
