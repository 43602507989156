<template>
<section class="header">
 <div class="wraper" >
  <div class="navigationcontenttop" v-show="!hideOnScroll"> 
    <div class="socialiconsection">
    <div class="social-icons">
    <a class="social-icon" href="https://www.facebook.com/vectorior" target="_blank">
      <div class="social-circle">
        <i class="fa fa-facebook"></i>
      </div>
    </a>
    <a class="social-icon" href="https://www.linkedin.com/company/vectorior" target="_blank">
      <div class="social-circle">
        <i class="fa fa-linkedin"></i>
      </div>
    </a>
    <a class="social-icon" href="https://www.pinterest.com/vectorior/">
      <div class="social-circle">
        <i class="fa fa-pinterest"></i>
      </div>
    </a>
  </div>
  </div>
  
  <template v-for="basicsetings in basicsetingalldata" :key="basicsetings.id">
    <div class="logosection"><a :href="url"><img  :src="addminurl+'roundimg/'+basicsetings.round_log"></a></div>
    <div class="contactsection text-black"><a style="color:#000!important"  href="https://api.whatsapp.com/send/?phone=8801894761921&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank"><i class="fa fa-phone" style="padding-right: 12px;text-decoration: none;"></i> +88{{basicsetings.Whats_App}}</a></div>
  </template>

</div>
<hr v-show="!hideOnScroll" style="border-top-width: 1px; border-top-style: solid; border-color:#dee2e6;">
<div class="navigationcontent">
 <div class="brand">
  <template v-for="basicsetings in basicsetingalldata" :key="basicsetings.id">
  <a :href="url">
   <img :src="addminurl+'logoimg/'+basicsetings.logo">
  </a>
</template>
 </div>
 <nav class="navigation">
  <ul>
    <li><router-link to="/" v-slot="{ navigate }"><a :class="{ active: isActive('/') }" @click="navigate">Home</a></router-link></li>
    <li><router-link to="/about"  v-slot="{ navigate }"><a :class="{ active: isActive('/about') }" @click="navigate">About Us</a></router-link></li>
    <li><router-link to="/pricing"  v-slot="{ navigate }"><a :class="{ active: isActive('/pricing') }" @click="navigate">Pricing</a></router-link></li>
    <li><a :class="{'active': isActive('/logo-to-vector-conversion') || isActive('/vector-illustration-and-artwork-service')}" @click="navigate">
    Services <i class="fa fa-chevron-down"></i>
    </a>
    <ul class="dropdown">
        <li><router-link to="/logo-to-vector-conversion"  v-slot="{ navigate }"><a href="#">Logo to vector conversion Service</a></router-link></li>
        <li><router-link to="/vector-illustration-and-artwork-service"  v-slot="{ navigate }"><a href="#">Vector Illustration and Artwork Service</a></router-link></li>
    </ul>
    </li>
    
    <li><router-link to="/blog"  v-slot="{ navigate }"><a :class="{ active: isActive('/blog') }" @click="navigate">Blog</a></router-link></li>
     <li><a target="_blank" href="https://calendly.com/vectorior/vectorior-free-consultation">BOOK AN APPOINMENT</a></li>  
     <li><router-link to="/reach-us"  v-slot="{ navigate }"><a  @click="navigate">Contact Us</a></router-link></li>
   </ul>
  </nav>
 </div>
</div>
</section>
<section class="mobileheader">
    <nav class="navbar navbar-default navbar-fixed-top">
    <img src="/img/Vectoriors.svg" alt="Logo" class="logo"/>
    <button class="navbar-toggler" @click="toggleNav">
      <span v-if="navOpen" class="close-icon">&#10005;</span> <!-- Close icon when open -->
      <span v-else class="toggler-icon">&#9776;</span> <!-- Hamburger icon when closed -->
    </button>
    <div class="navbar-menu" :class="{ 'is-active': navOpen }">
      <router-link to="/" href="#" class="navbar-item">Home</router-link>
      <router-link to="/about" href="#" class="navbar-item">About</router-link>
      <router-link to="/pricing" href="#" class="navbar-item">Pricing</router-link>
      <a @click="dropdownclick()" href="#" class="navbar-item">Services<i style="position:absolute;right:25px;" class="fa fa-chevron-down"></i></a>
      <router-link v-show="this.dropdownoption" to="/logo-to-vector-conversion" href="#" class="navbar-item"><i class="px-2 fa fa-angle-right"></i>Logo to vector conversion Service</router-link>
      <router-link v-show="this.dropdownoption" to="/vector-illustration-and-artwork-service" href="#" class="navbar-item"><i class="px-2 fa fa-angle-right"></i>Vector Illustration and Artwork Service</router-link>
      <router-link to="/blog" href="#" class="navbar-item">Blog</router-link>
      <a target="_blank" href="https://calendly.com/vectorior/vectorior-free-consultation" class="navbar-item">BOOK AN APPOINMENT</a>
      <router-link to="/reach-us" href="#" class="navbar-item">Contact Us</router-link>
    </div>
  </nav>
</section>
</template>
<script>
import { ref, onMounted, onUnmounted } from 'vue';
import axios from 'axios'
export default {
    setup() {
    // Reactive property for scroll position
    const hideOnScroll = ref(false);
    const navOpen = ref(false);
    // Method to update the hideOnScroll based on scroll position
    function handleScroll() {
      // Logic to determine when to hide the .navigationcontenttop and hr
      // For example, you might hide it after 100px of scrolling
      hideOnScroll.value = window.scrollY > 100;
    }

    function toggleNav() {
      navOpen.value = !navOpen.value;
    }

    // Lifecycle hooks to bind and unbind the scroll event
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    // Return the reactive properties
    return {
      hideOnScroll,
      navOpen, 
      toggleNav
    };
  },

  mounted(){
     this.getbasicsetings();
  },
  data() {
    return {
      basicsetingalldata:[],
      currentRoute: this.$route.path,
      isOpen: false,
      dropdownoption:false,
      languages: [
        { name: 'English', code: 'en', flag: 'img/lng/us.png' },
        { name: 'Français', code: 'fr', flag: 'img/lng/fr.png' },
        // ...add other languages here
      ],
      selectedLanguage: { name: 'English', code: 'en', flag: 'img/lng/us.png' }
    };
  },
  watch: {
    // Watch for route changes
    $route(to, from) {
      if (to.path !== from.path) {
        // Only toggle navOpen if the route has actually changed
        this.navOpen = !this.navOpen;
      }
      // Always update the currentRoute
      this.currentRoute = to.path;
    }
  },
  computed:{
    addminurl(){
      return this.$store.state.addminurl;

    },
    url(){
      return this.$store.state.url;
    },
  },
  methods: {
     getbasicsetings(){
      axios.get(this.addminurl+'api/getbasic_sectingvector').then(response=>{
        this.basicsetingalldata=response.data;

      });
    },
    dropdownclick(){
     this.dropdownoption= !this.dropdownoption;
    },
    isActive(route) {
      return this.currentRoute === route;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectLanguage(language) {
      this.selectedLanguage = language;
      this.isOpen = false;
    }
  }
};
</script>