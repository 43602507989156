<template>
  <div>
    <section>
      <div class="sectiongap"></div>
      <div class="custom-container">
        <div class="row" v-for="qualityvector in getqualityvectordata" :key="qualityvector.id">
          <div class="col-md-7 col-sm-12 col-lg-7">
           <h1 class="heading-title" ref="heading2"> {{qualityvector.titile_one}}</h1>
           <h2 class="heading-title2" ref="heading3">Get qualityvector custom vectorization services</h2>
           <p class="paragraph" ref="paragraph">
            {{qualityvector.description}}
          </p>
          <router-link to="/reach-us" class="readmore"> {{qualityvector.url_link}}</router-link>

        </div>
        <div class="col-md-5 col-sm-12 col-lg-5">
          <img style="width:100%;" :src="addminurl+'qualityvectorimg/'+qualityvector.img" class="img-fluid">
        </div>
      </div>
    </div>
  </section>
  <section class="services">
    <div class="custom-container  pt-5">
     <h1 class="middleheader ">Our Vector Conversion Services</h1>
     <p class="middleparagraph pb-5">Let us provide you with the best quality, manually traced vector conversion services.</p>
     <div class="row">
      <div class="col-md-4" v-for="allconversion in  conversionalldata" :key="allconversion.id">
        <div class="card box" >
          <div class="card-body servicebody">
            <div class="contentarea">
              <h2> {{allconversion.header_title}}</h2>
              <p> {{allconversion.description}}</p>
            </div>
            <router-link :to="allconversion.url_link" class="readmore servicereadmore">Learn More</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section >
 <div class="custom-container">
  <div class="row" v-for="bestvector in  bestvectoralldata" :key="bestvector.id">
    <div class="col-md-7">
      <div class="imagesection pt-5">
       <img style="width:100%;" :src="addminurl+'bestvectorimg/'+bestvector.img">
     </div>
   </div>  
   <div class="col-md-5">
    <h1 class="heading-title pt-5" ref="heading4"> {{bestvector.header_title}}</h1>
    <p class="paragraph" ref="heading5"> {{bestvector.description}}
    </p>
    <a :href="bestvector.url_link" target="_blank" class="readmore">FREE CONSULTATION</a>
  </div>
</div>
</div>
</section>
<section class="services">
  <div class="custom-container pt-5">
   <h1 class="middleheader ">Why Work With Us!</h1>
   <p class="middleparagraph pb-5">Highly skilled professionals make a custom vector design and provide dedicated client support</p>
   <div class="row pt-2">
    <div class="col-md-4"  v-for="withusalldata in  withusalldata" :key="withusalldata.id">
      <div class="card box">
        <div class="card-body whywork">
          <i aria-hidden="true" :class="withusalldata.fafa_link"></i>
          <h2>  {{withusalldata.title}}</h2>
          <p> {{withusalldata.description}}.</p>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

<section style="padding-bottom:0px;background:#3C3F63;">
  <div class="pricingarea">
    <div class="custom-container text-white ">
      <div class="row">
       <div class="col-md-8">
         <h1 class="heading-title text-white py-5 lh-lg">100% Quality Guarantee Vectorization Services and Order Bulk Amount with Affordable Price </h1>
       </div>
       <div class="col-md-4">
        <div class="py-5">
         <a class="readmore mt-5">FREE CONSULTAION</a>
       </div>
     </div>
   </div>
 </div>
</div>
</section>
<section class="pb-0 mb-0">
 <div class="custom-container">
  <div class="middleheader mt-0 mb-5">
    <h1 class="middleheader">How We Deliver <br>Vectorization Services</h1>
  </div>
  <div class="row mt-4" v-for="deliverorder in  getdeliver_orderalldata" :key="deliverorder.id">
    <div class="col-md-7 col-sm-12 col-lg-7">
      <h2 class="heading-title">{{deliverorder.header_title}}</h2>
      <div class="divider mt-3"></div><br>
      <p class="paragraph mt-2" ref="paragraph">
       {{deliverorder.description}}
     </p>
   </div>
   <div class="col-md-5 col-sm-12 col-lg-5">
    <img style="width:100%;" :src="addminurl+'diliverorderimg/'+deliverorder.img">
  </div>
</div>
<div class="row mt-4" v-for="convertring in  getdeliver_convertingalldata" :key="convertring.id">
  <div class="col-md-5 col-sm-12 col-lg-5">
   <img style="width:100%; " :src="addminurl+'diliverconverting/'+convertring.img">
 </div>
 <div class="col-md-7 col-sm-12 col-lg-7">
  <h2 class="heading-title"> {{convertring.header_title}}</h2>
  <div  class="divider mt-3"></div><br>
  <p class="paragraph" ref="paragraph">
   {{convertring.description}}
 </p>
</div>
</div>
<div class="row mt-4" v-for="prints in  getdeliver_printalldata" :key="prints.id">
  <div class="col-md-7 col-sm-12 col-lg-7">
    <h2 class="heading-title"> {{prints.header_title}}</h2>
    <div class="divider mt-3"></div><br>
    <p class="paragraph" ref="paragraph">
      {{prints.description}}
    </p>
  </div>
  <div class="col-md-5 col-sm-12 col-lg-5">
    <img style="width:100%;" :src="addminurl+'diliverPrintimg/'+prints.img">
  </div>
</div>
<!---Vector Art Conversion Price Starts At €10 --->

</div>
<div class="vectorformat pb-0">
  <div class="custom-container" ref="counterSection">
    <div class="row">
      <div class="col-md-3">
       <div class="projectbox text-center text-white py-5">
        <i aria-hidden="true" class="far fa-building"></i>
        <div class="increment py-3">{{this.projectsCount}}+</div>
        <h4>PROJECTS</h4>
      </div>
    </div> 
    <div class="col-md-3">
     <div class="projectbox text-center text-white py-5">
      <i aria-hidden="true" class="fas fa-users"></i>
      <div class="increment py-3">{{this.happyclients}}+</div>
      <h4>HAPPY CLIENTS</h4>


    </div>
  </div> 
  <div class="col-md-3 text-center text-white py-5">
   <div class="projectbox">
    <i aria-hidden="true" class="far fa-image"></i>
    <div class="increment py-3">{{this.vectordesign}}+</div>
    <h4>VECTOR DESIGN</h4>
  </div>
</div> 
<div class="col-md-3 text-center text-white py-5">
 <div class="projectbox">
  <i aria-hidden="true" class="fas fa-vector-square"></i>
  <div class="increment py-3">{{this.digitizing}}+</div>
  <h4>DIGITIZING</h4>
</div>
</div> 
</div>
</div>
</div>
</section>
<section class="services">
  <div class="custom-container">
   <h1 class="middleheader mt-0">Our Portfolio</h1>
   <p class="middleparagraph pb-5">Check out our recent logo vectorization services. We strive to provide you with excellent work at all times so that you can feel confident to rely on us for any future projects.</p>
   <div class="row">
    <div class="col-md-12">
          <div class="swiper mySwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(image, index) in portfolioimgall" :key="index" @click="openLightbox(index)">
                <img :src="addminurl+'portfolioimagefile/'+image.images" :alt="image.alt">
               </div>
            </div>
          </div>

    </div>


  </div>
</div>

</section>
<section class="services">
      <div class="custom-container">
       <h1 class="middleheader mt-0 pb-5">What Customer's Say About Us!</h1>
      <div class="row">
        <!-- Testimonial Section 1 -->
        <div class="col-md-6" v-for="customars in  customaralldata" :key="customars.id">
          <div class="justify-content-center mb-4">
            <div class="card testimonial-card shadow">
              <div class="card-body">
                <p class="card-text">
                  {{customars.description}}
                </p>
                <div class="testimonial-author d-flex align-items-center mt-3">
                  <img  alt="Customer Name" class="rounded-circle me-3" style="width: 60px; height: 60px;" :src="addminurl+'customarimg/'+customars.img">

                  <div>
                    <strong>{{customars.name}}</strong>
                    <br>
                    <span>{{customars.designation}}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </section>


<section class="services">
  <div class="custom-container">
    <h1 class="middleheader mt-0 pb-5">Recent Blog Of Vectorior</h1>
    <div class="row">
      <div class="col-md-4" v-for="blogalldata in  blogalldata" :key="blogalldata.id">
        <div class="Blogimg">
          <img style="width: 100%;" :src="addminurl+'blogimages/'+blogalldata.img">
       </div>
       <div class="card box blogbox">
        <div class="card-body"> 
         <h2  class="text-muted my-4">{{blogalldata.title}}</h2>
         <p>{{blogalldata.description}}</p>
         <a class="text-primary">{{blogalldata.url_link}}</a>
       </div>
       <div class="Blogline"></div>
       <span class="text-muted p-3">January 10, 2023</span>
     </div>
   </div>
</div>
</div>
</section>
<section class="footerbefor ">
 <div class="custom-container">
   <h1 class="middleheader" style="color:#c89211">  Customize the vector design that you can print in any size
   </h1>
   <h4 class="text-center">Converting raster images to vector preserves those fine details so you get large images with crisp, clean lines</h4>
   <a style="width:151px;display: block;margin:0 auto" class="readmore mt-5">GET FREE TRIAL</a>
 </div>
</section>

<section class="mb-0 pb-0">
  <div class="container-fluid">
    <div class="row">
      <template v-for="homgefooterimg in  gethomefootrimages" :key="homgefooterimg.id">
        <div class="col-md-12 col-sm-12 col-lg-12" >
       <img class="footer_image" :src="addminurl+'homefooter_img/'+homgefooterimg.images">
     </div>
      </template>
   </div>
 </div>
</section>

<vue-easy-lightbox
  :visible="isLightboxOpen"
  :imgs="images"
  :index="currentImageIndex"
  @hide="isLightboxOpen = false"
></vue-easy-lightbox>
</div>
</template>
<script>
  import { gsap } from 'gsap';
  import { ScrollTrigger } from 'gsap/ScrollTrigger';
  import { ref, onMounted } from 'vue';
  import VuePictureSwipe from '@itadori/vue-picture-swipe-control';
  import VueEasyLightbox from 'vue-easy-lightbox';
  gsap.registerPlugin(ScrollTrigger);
  import axios from 'axios'

  export default {

    components: {
      VuePictureSwipe,
      VueEasyLightbox,
    },
    computed:{
      addminurl(){
        return this.$store.state.addminurl;

      },
      url(){
        return this.$store.state.url;
      },
      images() {
       return this.portfolioimgall.map(image => this.addminurl + 'portfolioimagefile/' + image.images);
      }
    },
    data() {
      return {
        projectsCount: 0,
        happyclients:0,
        vectordesign:0,
        digitizing:0,
        isCounting: false,
        isLightboxOpen: false,
        currentImageIndex: 0,
        getqualityvectordata:[],
        conversionalldata:[],
        bestvectoralldata:[],
        withusalldata:[],
        getdeliver_orderalldata:[],
        getdeliver_convertingalldata:[],
        getdeliver_printalldata:[],
        portfolioimgall:[],
        customaralldata:[],
        gethomefootrimages:[],
        blogalldata:[],

      };
      },

      mounted(){
        window.addEventListener('scroll', this.checkIfInView);
        this.getqualityvectoralldata();

        this.getconversondata();
        this.getbestvectordatabas();

        this.getwithusedata();
        this.getdiverorderandsend();
        this.getdiverorderconverting();
        this.getdeliverprint();
        this.getportfolioimgdata();
        this.getcustomardata();
        this.gethomefooterimage();
        this.getblogdata();

        var swiper = new Swiper(".mySwiper", {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 15,
          stretch: 0,
          depth: 300,
          modifier: 1,
          slideShadows: true,
        },
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        }
        });


        },



      methods: {

       openLightbox(index) {
        this.currentImageIndex = index;
        this.isLightboxOpen = true;
        },

        getqualityvectoralldata(){
          axios.get(this.addminurl+'api/getqualityvector').then(response=>{
            this.getqualityvectordata=response.data;

          });

        },

        getconversondata(){
          axios.get(this.addminurl+'api/getconversiondata').then(response=>{
            this.conversionalldata=response.data;

          });

        },
        getbestvectordatabas(){
          axios.get(this.addminurl+'api/getbestvectrodata').then(response=>{
            this.bestvectoralldata=response.data;

          });

        },


        getwithusedata(){
          axios.get(this.addminurl+'api/getwithusdata').then(response=>{
            this.withusalldata=response.data;

          });

        },


        getdiverorderandsend(){
          axios.get(this.addminurl+'api/getdeliver_order_send').then(response=>{
            this.getdeliver_orderalldata=response.data;

          });

        },

        getdiverorderconverting(){
          axios.get(this.addminurl+'api/getdeliver_converting').then(response=>{
            this.getdeliver_convertingalldata=response.data;

          });

        },

        getdeliverprint(){
          axios.get(this.addminurl+'api/getdeliver_print').then(response=>{
            this.getdeliver_printalldata=response.data;

          });

        },

        getportfolioimgdata(){
          axios.get(this.addminurl+'api/getprotfolioimg').then(response=>{
            this.portfolioimgall=response.data;

          });

        },

        getcustomardata(){
          axios.get(this.addminurl+'api/getcustomardata').then(response=>{
            this.customaralldata=response.data;

          });

        },

        gethomefooterimage(){
          axios.get(this.addminurl+'api/gethomefooteimg').then(response=>{
            this.gethomefootrimages=response.data;

          });

        },
            getblogdata(){
      axios.get(this.addminurl+'api/getblogall').then(response=>{
        this.blogalldata=response.data;

      });

    },

    incrementCounter(target, property) {
          const interval = setInterval(() => {
            if (this[property] < target) {
              this[property]++;
            } else {
              clearInterval(interval);
            }
      },10); // Adjust the time interval as needed
        },
        checkIfInView() {
          if (this.$refs.counterSection) {
            const rect = this.$refs.counterSection.getBoundingClientRect();
            const isVisible = (rect.top >= 0 && rect.bottom <= window.innerHeight);

            if (isVisible && !this.isCounting) {
              this.isCounting = true;
              this.incrementCounter(40, 'happyclients');
              this.incrementCounter(160, 'projectsCount');
              this.incrementCounter(8000, 'digitizing');
              this.incrementCounter(23000, 'vectordesign');
            }
          }
        }
      }, 

      setup() {
        const heading2 = ref(null);
        const heading3 = ref(null);
        const paragraph = ref(null);
        const heading4 = ref(null);
        const heading5 = ref(null);
        const paragraph2 = ref(null);

        onMounted(() => {
      // Initial load animations
      gsap.from(heading2.value, { y: 20, opacity: 0, duration: 0.7, ease: 'power3.out' });
      gsap.from(heading3.value, { y: 20, opacity: 0, duration: 0.7, delay: 0.7, ease: 'power3.out' });
      gsap.from(paragraph.value, { y: 20, opacity: 0, duration: 0.7, delay: 1.4, ease: 'power3.out' });

      // Scroll-triggered animations
      const elementsToAnimateOnScroll = [
      heading4, 
      heading5, 
      paragraph2
      ];

      elementsToAnimateOnScroll.forEach((elRef, index) => {
        gsap.from(elRef.value, {
          scrollTrigger: {
            trigger: elRef.value,
            start: "top bottom-=100",
            toggleActions: "play none none reverse",
            once: true, // Trigger only once
          },
          y: 30,
          opacity: 0,
          duration: 0.7,
          delay: index * 0.2, // slight delay for each consecutive element
          ease: 'power3.out'
        });
      });
    });

        return {
          heading2, heading3, paragraph,
          heading4, heading5, paragraph2
        };
      }
    };
  </script>
  <style>
  figure a img{
   display: inline-block;
   max-width:285px;
   max-height:285px;
   background: silver;
   margin-bottom:10px;
 }
 .testimonial-card {
  border: none;
    border-radius: 15px; /* Rounded corners for the card */
    background: #f8f9fa; /* Light background for the card */
  min-height: 210px
}

.testimonial-card .card-body {
    padding: 30px; /* Spacious padding for inner content */
}

.testimonial-author img {
    border: 3px solid #fff; /* Optional: White border around the image */
}

</style>
<style>
  .slider{
    margin-top:30px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: -12px 5px 27px -15px rgba(0,0,0,0.75);
    -webkit-box-shadow: -12px 5px 27px -15px rgba(0,0,0,0.75);
    -moz-box-shadow: -12px 5px 27px -15px rgba(0,0,0,0.75);
  }
  .swiper {
    width: 100%;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 250px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    -webkit-box-reflect: below 1px linear-gradient(transparent, transparent , #0002 , #0004);
  }
</style>
