import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
   {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/PricingView.vue')
  },
  {
    path: '/logo-to-vector-conversion',
    name: 'services',
    component: () => import('../views/ServicesView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue')
  },
  {
    path: '/blog/2',
    name: 'blog2',
    component: () => import('../views/BlogView2.vue')
  },
  {
    path: '/vector-illustration-and-artwork-service',
    name: 'services2',
    component: () => import('../views/Services2View.vue')
  },
  {
    path: '/reach-us',
    name: 'contactus',
    component: () => import('../views/ContactusView.vue')
  },
  {
    path: '/apoinmecontactusnt',
    name: 'bookappoinment',
    component: () => import('../views/BookappoinmentView.vue')
  },
  
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
