<template>
<div class="whatsapp-container">
      <a href="https://api.whatsapp.com/send/?phone=8801894761921&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" style="color:white">
        <div class="whatsapp-icon"><i class="fa fa-whatsapp" aria-hidden="true"></i></div>
        <div class="qr-code-container" style="display: none;">
            <img src="/img/qrscanner.png" alt="QR Code" class="qr-code">
            <div class="arrow"></div>
        </div>
      </a>
 </div>	
 <footer>
 	<div class="custom-container py-5">
 	 <div class="row" v-for=" basicsetings in basicsetingalldata" :key="basicsetings.id">
 	 	<div class="col-md-3">
 	 	<div class="usefllink">
 	 	<h2>Useful Links</h2>
 	 	  <ul >
		 	 <li><router-link to="/" ><a>Home</a></router-link></li>
		 	  <li><router-link to="/about"><a>About Us</a></router-link></li>
		 	  <li><router-link to="/pricing"><a>Pricing</a></router-link></li>
		 	  <li><router-link to="/services"><a>Services</a></router-link></li>
		 	  <li><router-link to="/blog"><a>Blog</a></router-link></li>
 	 	  </ul>
 	 	 </div>
 	   </div>
 	   <div class="col-md-6">
 	   	<div class="aboutus">
 	 	  <h2>About us</h2>
 	 	  <p class="text-white">{{basicsetings.desciption}}</p>
 	 	</div>
 	 	</div>
 	    <div class="col-md-3">
 	    <div class="companyinformation">
 	 	  <h2>Contact Us</h2>
 	 	  <ul>
 	 	  	<li><a><i class="fa fa-map-marker"></i>&nbsp;&nbsp; {{basicsetings.location}}</a></li>
 	 	  	<li><a><i class="fa fa-envelope"></i>{{basicsetings.email}}</a></li>
 	 	  	<li><a><i class="fa fa-whatsapp"></i>What’s App: +880{{basicsetings.Whats_App}}</a></li>
 	 	  </ul>
 	 	  <ul class="socialicon">
 	 	  	<li><a class="text-white" href="https://www.facebook.com/vectorior" target="_blank"><i class="fa fa-facebook" ></i></a></li>
 	 	  	<li><a class="text-white" href="https://www.linkedin.com/company/vectorior" target="_blank"><i class="fa fa-linkedin"></i></a></li>
 	 	  	<li><a class="text-white" href="https://www.pinterest.com/vectorior/"><i class="fa fa-pinterest"></i></a></li>
 	 	  </ul>
 	 	</div>
 	   </div>
 	 </div>
 	</div>
 	 <div class="footerfixed">
 	 <div class="custom-container">
 	 	<div class="row">
 	 	 <div class="col-md-12 col-sm-12 col-lg-6 text-center text-lg-left"><p class="mt-1 mb-0 mb-lg-3 mt-lg-3">Copyright © 2023 Vectorior</p></div>
 	 	 <div class="col-md-12 col-sm-12 col-lg-6 text-center text-lg-right"><p class="mt-1 mb-0 mb-lg-3 mt-lg-3">Privacy Policy | Terms and Conditions</p></div>
 	 	</div>
 	 </div>
 	</div>
 </footer>
</template>

<script type="text/javascript">
    import axios from 'axios'
    export default{
        computed:{
    addminurl(){
      return this.$store.state.addminurl;

    },
    url(){
      return this.$store.state.url;
    },
    },
    data(){
        return{
                 
          basicsetingalldata:[],

        }
               
        },
         mounted(){
            this.getbasicsetings();

    },
    methods:{


     getbasicsetings(){
      axios.get(this.addminurl+'api/getbasic_sectingvector').then(response=>{
        this.basicsetingalldata=response.data;

      });
    },




    }




    }

</script>

<style>
  .whatsapp-container {
        position: fixed;
        bottom: 70px;
        right: 10px;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #25d366;
        background-size: 60%;
        display: grid;
        place-items:center;
        z-index: 9999999999999999999999999999;

        }
        .whatsapp-container .fa{
           font-size:40px; 
           position: relative;
          top: 5px;
          left: 8px;
         }
        .whatsapp-container .qr-code-container {
         position: absolute;
         bottom: 46px;
         right: 0;
         padding-bottom: 15px;  /* Adjusted space for the arrow */
         align-items: center;  /* Align children (qr code and arrow) in center */
         display: flex;
         flex-direction: column;  /* Stack children vertically */
        }
        .whatsapp-icon {
            width: 50px;
            height: 50px;
        }
        .qr-code {
            width: 228px;
            min-height: 200px;
            border-radius: 5px;
        }
       .arrow {
        width: 0;
        height: 0;
        border-left: 165px solid transparent;
        border-right: 7px solid transparent;
        border-top: 20px solid white;
       }

</style>
